interface LoginCredentials {
  email: string;
  password: string;
}

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  status: string;
  id: number;
}

interface LoginResponse {
  success: boolean;
  token?: string;
  userId?: number;
  error?: string;
  mobile?: string;
  data?: UserData;
  is2FaEnable?: boolean;
}

export const login = async (credentials: LoginCredentials): Promise<LoginResponse> => {
  try {
    const response = await fetch(`http://localhost:4000/admin/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error during login:', response.status, errorData.error);
      return { success: false, error: errorData.error };
    }

    const userData: LoginResponse = await response.json();

    return { success: true, token: userData.token, data: userData.data };
  } catch (error) {
    console.error('Error during login:', error);
    return { success: false, error: 'An error occurred during login.' };
  }
};
