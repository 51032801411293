import React, { useCallback, useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import Select from "../../components/Select";
import Modal from "../../components/Modal";
import { useSelector } from "react-redux";
import AlertModal from "../../components/Alert";
import PageLoader from "../../components/PageLoader";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
interface WorkflowProps { }

interface User {
  firstName: string;
  lastName: string;
}
interface Participant {
  participates: any;
  id: number;
  userId: number | null;
  email: string;
  roleId: number | null;
  isInvited: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  roles: any[]; // You may want to define a proper type for roles
}
interface Task {
  id: number;
  name: string;
  workspaceId: number;
  dueDate: string | null;
  userId: number;
  teamId: number | null;
  allocate: number | null;
  taskNum: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  user: User;
  participates: any; // You may want to define a proper type for participates
}

interface ApiResponse {
  name: string;
  data: Task[];
}

interface selectInter {
  value: number;
  label: string;
}
interface TeamMember {
  presentPassword: string;
  email: string;
  password: string;
  permission: string;
}
interface members {
  firstName: string,
  lastName: string,
  status: boolean,
  email: string,
  id: string
}
interface responseInterface {
  users: members[],
  error: string,
  message: string
}
const Workflow: React.FC<WorkflowProps> = () => {
  const [members, setmembers] = useState<members[]>([])
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [showTeamMemberAddModal, setShowTeamMemberAddModal] =
    useState<boolean>(false);
  const [alertShow, setalertShow] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [message, setmessage] = useState<string>("");
  const [typeOfAlert, settypeOfAlert] = useState<string>("");
  const [newTeamMemberState, setNewTeamMemberState] = useState<TeamMember>({
    presentPassword: "",
    email: "",
    password: "",
    permission: '',
  });

  const { token, userInfo } = useSelector((state: any) => state.userToken);

  const handleEmailInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewTeamMemberState({
        ...newTeamMemberState,
        email: e.target.value,
      });
    },
    [newTeamMemberState]
  );



  const handleNewTeamMember = async () => {
    try {
      const response = await fetch(`http://api.workspace.tesseractsquare.com/admin/invite-member`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ role: selectedStatus, email: newTeamMemberState.email })
      });
      const authResponse = await response.json();
      if (response.ok) {
        settypeOfAlert("success")
        setmessage("Inviatation send successfully...")
        setalertShow(true)
      } else {
        settypeOfAlert("error")
        setmessage(authResponse.error)
        setalertShow(true)
      }
    } catch (error) {
      console.error("Error during Auth:", error);
    }
  }
  const handleRemoveId = async (id: string) => {
    console.log(newTeamMemberState)
    try {
      const response = await fetch(`http://api.workspace.tesseractsquare.com/admin/remove/${id}`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const authResponse: { error: string } = await response.json();
      if (response.ok) {
        settypeOfAlert("success")
        setmessage("Deleted Successfully...!")
        setalertShow(true)
      } else {
        settypeOfAlert("error")
        setmessage(authResponse.error)
        setalertShow(true)
      }
    } catch (error) {
      console.error("Error during Auth:", error);
    }
  }

  const fetchMemberList = async (): Promise<void> => {
    try {
      const response = await fetch(`http://api.workspace.tesseractsquare.com/admin/members`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const authResponse: responseInterface = await response.json();
      if (response.ok) {

        setmembers(authResponse.users)
      }
    } catch (error) {
      console.error("Error during Auth:", error);
    }
  }
  useEffect(() => {
    fetchMemberList()
  }, [])

  useEffect(() => { }, []);

  return (
    <>
      <AdminNavbar />
      <div className="top-action-bar style-2 d-sm-flex flex-wrap align-items-center">
        <div className="d-flex align-items-center gap-5">
          <button
            type="button"
            className="part-team-add-btn__v2"
            onClick={() => setShowTeamMemberAddModal(true)}
          >
            <span>
              <i className="icon-plus"></i>
            </span>
            Add Team Member
          </button>
        </div>
      </div>
      <div
        style={{
          margin: "20px",
        }}
      >
        <div
          style={{
            padding: "16px",
            background: "#F9FAFB",
            width: "30%",
            borderBottom: "1px #EAECF0 solid",
          }}
        >
          Team Member
        </div>
        <List
          sx={{ width: "100%", maxWidth: 700, bgcolor: "background.paper" }}
        >
          {members.map((member: members, index) => {
            const labelId = `checkbox-list-label-${index}`;
            return (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton edge="end" aria-label="comments"></IconButton>
                }
                style={{ width: "100%" }}
                disablePadding
              >
                <ListItemButton role={undefined} dense>
                  <ListItemIcon>
                    <img
                      style={{
                        borderRadius: "200px",
                        width: "32px",
                        height: "32px",
                      }}
                      alt="Avatar"
                      src="https://gravatar.com/avatar/4ac2aa1acc625d54512378128cceb250?s=400&d=robohash&r=x"
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId}>
                    {
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                          <span>{member.firstName} {member.lastName}</span>
                          <span
                            style={{
                              color: "#667085",
                            }}
                          >
                            {member.email}
                          </span>
                        </div>
                        <div
                          style={{
                            width: "130px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#027A48",
                              background: "#ECFDF3",
                              borderRadius: "16px",
                              padding: "3px",
                              margin: "0px 15px",
                            }}
                          >
                            {member.status}
                          </span>
                        </div>
                        {
                          userInfo.role == "Superamin" ? <span
                            onClick={() => handleRemoveId(member.id)}
                            style={{
                              color: "#B42318",
                            }}
                          >
                            Remove
                          </span> : null
                        }
                      </div>
                    }
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
      {loading ? <PageLoader /> : <div></div>}
      <Modal
        show={showTeamMemberAddModal}
        onSave={handleNewTeamMember}
        onSaveBtnText="Invite"
        onCancel={() => {
          setShowTeamMemberAddModal(false);
        }}
      >
        <h5 className="modal-title">New Team Member</h5>
        <div className="form-group">
          <label htmlFor="" className="form-label color-2">
            Email
          </label>
          <input
            type="email"
            name="presentPassword"
            onChange={handleEmailInput}
            className={`form-control`}
            value={newTeamMemberState.email}
          />
        </div>
        <div className="form-group">
          <Select
            placeholder="Permission"
            defaultSelectedValue={selectedStatus}
            options={userInfo?.role === "Superamin" ? [
              { value: "Superadmin", label: "SuperAdmin" },
              { value: "Member", label: "Member" },
            ] : [
              { value: "Member", label: "Member" },
            ]}
            onChange={(e) => setSelectedStatus(e)}
          />

        </div>
      </Modal>
      <AlertModal setShow={setalertShow} show={alertShow} msg={message} type={typeOfAlert} />
    </>
  );
};

export default Workflow;
