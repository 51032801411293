import { NavLink, useNavigate, useParams } from "react-router-dom";
import AdminNavbar from "../../components/AdminNavbar";
import workspaceImg from "../../assets/img/avatar-placeholder.svg";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { SERVER_BASE_URL } from "../../constants/urles";
import { useSelector } from "react-redux";
import AlertModal from "../../components/Alert";

interface workSpaceInterface {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  purpose: string;
  type: string;
  setting: {
    isQANotification: boolean;
    isTeamSpecificQA: boolean;
  };
  user: {
    email: string;
    mobile: string; // You might want to replace 'any' with the actual type
  };
}

const initialProfile: workSpaceInterface = {
  id: 4,
  name: "",
  description: "",
  imageUrl: "",
  purpose: "",
  setting: {
    isTeamSpecificQA: false,
    isQANotification: false,
  },
  type: "",
  user: {
    email: "",
    mobile: "",
  },
};
const Settings: React.FC = () => {
  const [selectedPurpose, setSelectedPurpose] = useState<string>("")
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [updatename, setupdatename] = useState<string>("");
  const [profileDetail, setprofileDetail] =
    useState<workSpaceInterface>(initialProfile);
  const [alertShow, setalertShow] = useState<boolean>(false);
  const [message, setmessage] = useState<string>("");
  const [typeOfAlert, settypeOfAlert] = useState<string>("");
  const [oldPassword, setoldPassword] = useState<string>("");
  const [newPassword, setnewPassword] = useState<string>("")
  const [newEmail, setnewEmail] = useState<string>('')
  const { token, userInfo } = useSelector((state: any) => state.userToken);
  const params = useParams();
  const navigate = useNavigate();
  const handleToggleModal = (type: string, message: string) => {
    setmessage(message);
    settypeOfAlert(type);
    setalertShow(true);
  };

  const handleChangePassword = async (): Promise<void> => {
    try {

      console.log("called")
      const response = await fetch(`http://api.workspace.tesseractsquare.com/admin/change-password`, {
        method: "PATCH",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ oldPassword: oldPassword, newPassword: newPassword }),
      });
      const authResponse = await response.json();
      if (!response.ok) {
        handleToggleModal("error", authResponse.error)
        return;
      }
      settypeOfAlert("success")
      setmessage("Password Changed Successfully...")
      setalertShow(true)
      setShowPasswordModal(false)
    } catch (error) {
      setShowPasswordModal(false)

    }
  }

  const handleChangeEmail = async (): Promise<void> => {
    try {
      console.log("called")
      const response = await fetch(`http://api.workspace.tesseractsquare.com/admin/change-email`, {
        method: "PATCH",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password: oldPassword, newEmail: newEmail }),
      });

      const authResponse = await response.json();
      if (!response.ok) {
        handleToggleModal("error", authResponse.error)
        return;
      }
      settypeOfAlert("success")
      setmessage("Email Changed Successfully...")
      setalertShow(true)
      setShowEmailModal(false)
    } catch (error) {
      setShowEmailModal(false)
    }
  }

  return (
    <>
      <AdminNavbar />
      <div className="settings-wrap" style={{
        marginTop: "95px"
      }}>
        <div className="row">
          <div className="col-lg-12">
            <div className="settings-profile-wrap d-inline-block position-relative">
              <div className="settings-profile-img" style={{
                padding: "24px",
                borderRadius: "100%",
                background: "#F6F6F6"
              }}>
                <img
                  src={
                    profileDetail?.imageUrl
                      ? profileDetail.imageUrl
                      : workspaceImg
                  }
                  alt=""
                />
              </div>
              <button className="settings-profile-btn position-absolute">
                <i className="icon-camera"></i>
                <input
                  type="file"
                  className="position-absolute"
                />
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <h5 className="settings-title">Account Email</h5>
            <div className="settings-group">
              <span>
                {userInfo.email}
                <button
                  type="button"
                  className="btn btn-link text-purple"
                  onClick={() => {
                    setShowEmailModal(true);
                    setupdatename(profileDetail?.name);
                  }}
                >
                  Change
                </button>
              </span>
              <p className="settings-text">{profileDetail?.name}</p>
            </div>
            <h5 className="settings-title">Present Password</h5>
            <div className="settings-group">
              <span>
                *************************
                <button
                  type="button"
                  className="btn btn-link text-purple"
                  onClick={() => {
                    setShowPasswordModal(true);
                  }}
                >
                  Change
                </button>
              </span>
              <p className="settings-text">{profileDetail?.name}</p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showEmailModal}
        onSave={handleChangeEmail}
        onSaveBtnText="Save"
        onCancel={() => setShowEmailModal(false)}
      >
        <h5 className="modal-title">Change Email</h5>
        <div className="form-group">
          <label htmlFor="" className="form-label">
            Present Password
          </label>
          <input
            type="password"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setoldPassword(e.target.value)
            }
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="" className="form-label">
            New Email
          </label>
          <input type="email" onChange={(e) => setnewEmail(e.target.value)} className="form-control" />
        </div>
      </Modal>
      <Modal
        show={showPasswordModal}
        onSave={handleChangePassword}
        onSaveBtnText="Save"
        onCancel={() => setShowPasswordModal(false)}
      >
        <h5 className="modal-title">Change Account Password</h5>
        <div className="form-group">
          <label htmlFor="" className="form-label">
            Present Password
          </label>
          <input
            type="password"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setoldPassword(e.target.value)
            }
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="" className="form-label">
            New Password
          </label>
          <input type="password" onChange={(e) => setnewPassword(e.target.value)} className="form-control" />
        </div>
        <div className="form-group">
          <label htmlFor="" className="form-label">
            Confirm New Password
          </label>
          <input type="password" onChange={(e) => setnewPassword(e.target.value)} className="form-control" />
        </div>
      </Modal>
      <AlertModal
        setShow={setalertShow}
        show={alertShow}
        msg={message}
        type={typeOfAlert}
      />
    </>
  );
};

export default Settings;
