import { LOGOUT, SET_USER_INFO, SET_USER_TOKEN } from "../constants/useConstant";

export const userActions = (payload: string) => ({
  type: SET_USER_TOKEN,
  payload,
});
export const userInfoAction = (payload: any) => ({
  type: SET_USER_INFO,
  payload,
});
export const logoutAction = () => ({
  type: LOGOUT,
});
