type Routes = {
  AdminLogin: string;
  AdminSignUp: string;
  AdminWorkspace: string;
  AdminProfile: string;
  AdminTeam: string;
  AdminSetting: string;
  Admin_invitation_Signup: string;
  Forgot_Password: string;
  Reset_Password: string
};

const routes: Routes = {
  AdminLogin: "/",
  AdminSignUp: "/admin/sign-up",
  AdminWorkspace: "/admin/workspace",
  AdminProfile: "/admin/profile",
  AdminTeam: "/admin/team",
  AdminSetting: "/admin/setting",
  Admin_invitation_Signup: "/admin/invitation/signup/:id",
  Forgot_Password: "/forgot-password",
  Reset_Password: "/admin/reset-password/:token"
};

export default routes;