import { BrowserRouter, Route, Routes } from "react-router-dom";

import routes from "./constants/routes";

import AdminLogin from "./pages/admin/AdminLogin";
import AdminSignUp from "./pages/admin/AdminSignUp";
import AdminWorkspace from "./pages/admin/Workspace";
import AdminTeam from "./pages/admin/Team";
import AdminSetting from "./pages/admin/Setting";
import AdminProfile from "./pages/admin/AdminProfile";
import InvitationSignup from "./pages/admin/InvitationSignup";
import ForgetPassword from "./pages/ForgetPassword";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.AdminLogin} element={<AdminLogin />} />
        <Route path={routes.AdminSignUp} element={<AdminSignUp />} />
        <Route path={routes.AdminWorkspace} element={<AdminWorkspace />} />
        <Route path={routes.AdminTeam} element={<AdminTeam />} />
        <Route path={routes.AdminProfile} element={<AdminProfile />} />
        <Route path={routes.AdminSetting} element={<AdminSetting />} />
        <Route path={routes.Admin_invitation_Signup} element={<InvitationSignup />} />
        <Route path={routes.Forgot_Password} element={<ForgetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;