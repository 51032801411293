
import { LOGOUT, SET_USER_INFO, SET_USER_TOKEN } from '../constants/useConstant';

const initialState: any = {
  token: '',
  userInfo: {}
};

const userTokenReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_TOKEN:
      return { ...state, token: action.payload };
    case SET_USER_INFO:
      return { ...state, userInfo: action.payload }
    case LOGOUT:
      return { ...state, token: "" }
    default:
      return state;
  }
};

export default userTokenReducer;
