import React, { useCallback, useEffect, useState } from "react";
import logoImg from "../assets/img/logo-purple.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../stateManagement/actions/useAction";
import routes from "../constants/routes";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    if (document.body.classList.contains("nav-open")) {
      document.body.classList.remove("nav-open");
      document.body.style.overflow = "auto";
    }
  }, []);

  return (
    <nav className="top-nav">
      <div className="top-nav-logo" onClick={() => navigate("/workspace")}>
        <img src={logoImg} alt="" />
      </div>
      <div className="top-nav-links">
        <ul>
          <li>
            <NavLink to={routes.AdminWorkspace}>Workspace</NavLink>
          </li>
          <li>
            <NavLink to={routes.AdminTeam}>Admin</NavLink>
          </li>
          <div style={{float : "right"}}>
            <NavLink
              to={routes.AdminSetting}
              className="top-nav-icon-btn is-setting-btn"
            >
              <i className="icon-settings"></i>
            </NavLink>
            <button
              type="button"
              className="top-nav-icon-btn is-logout-btn"
              onClick={() => {
                dispatch(logoutAction());
                navigate("/");
              }}
            >
              <i className="icon-logout"></i>
            </button>
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;